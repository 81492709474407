import gsap from "gsap";

export default class Hero {
    constructor(rootNode, props) {
        this.root = rootNode;

        this.initLoadingAnimation();
    }

    initLoadingAnimation() {
        const selectors = [
            ".hero-icon",
            "h1",
            "p",
            ".cta-border",
            ".hero-header svg:nth-of-type(1)",
            ".hero-header svg:nth-of-type(2)",
        ];

        const elements = selectors.map((selector) =>
            this.root.querySelector(selector)
        );

        gsap.from(elements, {
            opacity: 0,
            y: 40,
            duration: 0.7,
            stagger: 0.15,
            ease: "power2.out",
        });
    }
}
