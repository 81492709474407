import gsap from "gsap";

export default class LinkList {
    constructor(rootNode, props) {
        this.root = rootNode;
        this.activeClass = "active";
        this.durationUntilHeadlineChange = 2000;

        this.initLoadingAnimation();
        this.initHeadlineAnimation();
    }

    initLoadingAnimation() {
        const elements = [
            this.root.querySelector(".main-icon"),
            this.root.querySelector("h1"),
            this.root.querySelectorAll("ul > li"),
            document.querySelectorAll(".Header svg"),
        ];

        gsap.from(elements, {
            opacity: 0,
            y: 40,
            duration: 0.7,
            stagger: 0.1,
            ease: "power2.out",
        });
    }

    initHeadlineAnimation() {
        const headline = this.root.querySelector("h1");
        const spans = headline.querySelectorAll("span");

        let current = 0;

        gsap.fromTo(
            spans[current],
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0 }
        );

        gsap.ticker.lagSmoothing(0);

        setInterval(() => {
            const timeline = gsap.timeline({
                defaults: { duration: 1, ease: "power2.out" },
            });
            timeline.to(spans[current], { opacity: 0, y: 20 });

            current++;
            current %= spans.length;

            timeline.fromTo(
                spans[current],
                { opacity: 0, y: -20 },
                { opacity: 1, y: 0 },
                "-=0.5"
            );
        }, this.durationUntilHeadlineChange + 1500); //1500ms = 1s + 0,5s = duration fade in + (duration fade out - 0,5s earlier start)
    }
}
